<template>
  <WhatTypeOfFramesField
    :choices="choices"
    v-on="$listeners"
  />
</template>

<script>
import WhatTypeOfFramesField from 'chimera/all/components/form/fields/typeOfWork/TypeOfWorkField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'WhatTypeOfFramesFormPart',

  components: {
    WhatTypeOfFramesField
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      choices: [
        new Selectable(this.$t('window'), this.$t('window'), this.$t('window')),
        new Selectable(this.$t('door'), this.$t('door'), this.$t('door')),
        new Selectable(this.$t('both'), this.$t('both'), this.$t('both')),
        new Selectable(this.$t('global.other'), this.$t('global.other'), this.$t('global.other'))
      ]
    }
  }
}
</script>

<i18n>
{
  "nl-NL": {
    "window": "Raamkozijnen",
    "door": "Deurkozijnen",
    "both": "Allebei"
  }
}
</i18n>
