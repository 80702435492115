<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <EstimatedNumberOfFramesFormPart @change="onChange" @result="onResult" />

      <h2 class="form-modal__part-title">
        Type kozijnen
      </h2>

      <WhatTypeOfFramesFormPart @change="onChange" @result="onResult" />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import EstimatedNumberOfFramesFormPart from 'chimera/windowFrames/components/form/parts/estimatedNumberOfFrames/EstimatedNumberOfFramesFormPart'
import WhatTypeOfFramesFormPart from 'chimera/windowFrames/components/form/parts/whatTypeOfFrames/WhatTypeOfFramesFormPart'
import NextStep from '~/pages/offertes-aanvragen/je-gegevens'

export default {
  name: 'EstimatedNumberOfFramesWhatTypeOfFramesFormStep',

  components: {
    EstimatedNumberOfFramesFormPart,
    WhatTypeOfFramesFormPart,
    FormErrorMessages
  },

  extends: AbstractFormStep,

  data: () => ({
    willTransitionOnValid: true
  }),

  /**
   * Pass through the submit event given from FormModal
   */
  created () {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed () {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition () {
      this.routeTo(NextStep)
    }
  }
}
</script>
