<template>
  <ServiceComboFormPart
    :service-types="serviceTypes"
    :service-selections="serviceSelections"
    v-on="$listeners"
  />
</template>

<script>
import {
  windowFramesAluminiumConsumerBE,
  windowFramesAluminiumCorporateBE,
  windowFramesPlasticConsumerNL,
  windowFramesPlasticCorporateNL
} from 'chimera/windowFrames/service'
import ServiceComboFormPart, {
  consumerKey,
  corporateKey
} from 'chimera/all/themes/blueflow/components/form/part/service/ServiceComboFormPart'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceComboFormPart
  },

  /**
   * PD-3935 - Added original service to serviceSelections
   *
   * @returns {object}
   */
  data () {
    return {
      serviceTypes: [
        new Selectable('Woning', 'Woning', consumerKey),
        new Selectable('Bedrijfspand', 'Bedrijfspand', corporateKey)
      ],

      serviceSelections: [
        new Selectable(
          'Kunststof kozijnen',
          'Kunststof kozijnen',
          'Kunststof kozijnen',
          {
            consumer: windowFramesPlasticConsumerNL,
            corporate: windowFramesPlasticCorporateNL
          }
        ),
        new Selectable(
          'Aluminium kozijnen',
          'Aluminium kozijnen',
          'Kunststof kozijnen',
          {
            consumer: windowFramesPlasticConsumerNL,
            corporate: windowFramesPlasticCorporateNL,
            original: {
              consumer: windowFramesAluminiumConsumerBE,
              corporate: windowFramesAluminiumCorporateBE
            }
          }
        )
      ]
    }
  }
}
</script>
